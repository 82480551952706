.topbar { position: relative; min-height: 80px; display: flex; align-items: center; background-color: #141414; background-image: url(../img/topbar_bg.jpg); background-position: top left; background-repeat: no-repeat; color: #fff; z-index: 1;
    .grid-container { padding-top: 1rem; padding-bottom: 1rem; }
    p { margin-bottom: 0; }
}

.popup { position: relative; max-width: 670px; height: 100vh; height: calc(var(--vh, 1vh) * 100); padding: 40px 0 0; border: none; outline: none; background-color: #23CF5F; color: #FFF;
    @include breakpoint(small only) { top: 0 !important; }
    @include breakpoint(medium) { height: auto; padding-top: 110px; overflow: hidden; }
    .close-button { font-size: rem-calc(34); color: #fff; 
        &:hover, &:focus { color: #141414; }
    }

    &--content { position: relative; padding: 0 30px 30px; z-index: 2; 
        @include breakpoint(large) { padding: 0 60px 60px; }
        &__title { margin-bottom: rem-calc(30); font-size: rem-calc(30); font-weight: bold; }
        &__footer { margin-top: rem-calc(30); }
        
        a { text-decoration: underline; font-weight: bold; color: #FFF; 
            &:hover { color: #000; }
        }
        p { margin-bottom: 0; font-size: rem-calc(20); }
    }

    &::before, &::after {
        @include breakpoint(medium) { content: ""; position: absolute; background-color: #FFF; }
    }
    &::before { width: 100%; height: 200px; border-bottom: 80px solid rgb(73, 206, 120);     transform: skewY(-12deg) translateY(-195px); }
    &::after { width: 170px; height: 60px; bottom: 0; right: 0; transform: skewY(-18deg) translateY(38px); }
}