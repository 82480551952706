.services-preview { margin-bottom: rem-calc(30);
  @include breakpoint(medium) { 
    margin-bottom: rem-calc(30);
  }
  @include breakpoint(large) { 
    margin-bottom: rem-calc(50);
  }

  .header + & { position: relative; margin-top: -1 * rem-calc(30);
    @include breakpoint(medium) { 
      margin-top: -1 * rem-calc(50);
    }
  }

  .clients-testimonials + & { position: relative; }

  .stripe { position: absolute; top: 80px; left: 50%; width: 100vw; height: 350px; margin-left: -50vw; background: $white; transform: skewY(-12deg); transform-origin: 0;
    @include breakpoint(medium) { 
      top: 320px;
    }
    @include breakpoint(large) { 
      top: 270px;
    }
  }
  .grid-x { position: relative; }
  .injected-svg { display: inline-block; width: 60px; height: 53px; margin-bottom: rem-calc(30);
    @include breakpoint(medium) { margin-bottom: rem-calc(50); }
  }

  p:last-of-type:not(:first-child):not(:last-child) { margin-bottom: rem-calc(20);
    @include breakpoint(large) { 
      margin-bottom: rem-calc(55);
    }
  }

  &--aside {
    .injected-svg { margin-bottom: 0; }
    .more { padding: 10px; }

    .card { 
      &, #Group-10, #Group-9, .icon-stripe { transition: all .3s $animation-easeoutquint; }
      &:hover { box-shadow: 0 14px 17px 0 rgba(0,0,0,0.05); transform: translate3d(0,-5px,0);
        #Group-9, #Group-10 { transform: scale(1.1); }
        .icon-stripe { transform: translate3d(10px,0,0); }
        .more { transform: translate3d(5px,0,0) scale(1.3); }
      }
    }
  }
} // .services-preview