.areas-preview { position: relative; z-index: 1;
  h2 {
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(25);
    }
  }

  .cell { margin-bottom: rem-calc(30);
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(60);
    }
    @include breakpoint(large) { 
      margin-bottom: rem-calc(120); padding-right: rem-calc(30); padding-left: rem-calc(30);
    }

    &.icon {
      svg, img, h3 { margin-bottom: rem-calc(20);
        @include breakpoint(large) { 
          margin-bottom: rem-calc(40);
        }
      }
      h3 { line-height: 1.5; }
    }
  } // .cell

  &--steps { counter-reset: steps;
    .cell { position: relative; padding-left: 50px; counter-increment: steps;
      @include breakpoint(medium) { 
        padding-left: 70px; 
      }
      &::before { content: counter(steps, decimal-leading-zero); position: absolute; left: 5px; font-size: rem-calc(30); line-height: 1; color: $coopers;
        @include breakpoint(medium) { 
          font-size: rem-calc(40);
        }
      }
    }
  }

  .section--intro & {
    .cell {
      @include breakpoint(medium) { 
        margin-bottom: 0; 
      }
    }
  }
} // .areas-preview