.specialization { padding-top: rem-calc(30); padding-bottom: rem-calc(30); background: #141414; color: #fff;
  @include breakpoint(medium) { 
    padding-top: rem-calc(60); padding-bottom: rem-calc(60); 
  }
  @include breakpoint(large) { 
    padding-top: rem-calc(90); padding-bottom: rem-calc(90); 
  }
  .content { margin-bottom: rem-calc(50);
    @include breakpoint(large) { 
      margin-bottom: 0;
    }
  }
  h2 { margin-bottom: rem-calc(25); color: inherit;
    @include breakpoint(large) { 
      margin-bottom: rem-calc(50);
    }
  }
  p.lead { max-width: 570px; margin-bottom: rem-calc(30); }

  .editor { position: relative; margin-top: rem-calc(30); z-index: 2;
    @include breakpoint(medium) { 
      margin-top: rem-calc(60);
    }
    @include breakpoint(large) { 
      margin-top: rem-calc(90);
    }
  }
} // .specialization

.specialization__skill { position: relative; margin-bottom: 2 * $global-padding; padding: $global-padding; border-radius: $global-radius; background: #222; transition: all .3s $animation-easeoutquint; z-index: 2;
  @include breakpoint(medium) { 
    padding: rem-calc(20);
  }

  &:hover { background: lighten(#222, 5%); transform: translate3d(0,-5px,0); }

  img { @include vertical-center; width: 40px; }
  figcaption { margin-left: 60px; }
  h6 { margin-bottom: 10px; }
  p { margin-bottom: 0; }
}

.editor { max-width: 940px; margin: 0 auto;
  .tabs, .tabs-content { border: none; }
  .tabs { border-radius: $global-radius $global-radius 0 0; background: #2d2d2d; overflow: hidden; }
  .tabs-title > a:hover, .tabs-title > a:focus, .tabs-title > a[aria-selected='true'], .tabs-panel { background: #222; }
  pre { max-height: 590px; }
  pre.line-numbers { padding-left: 1.8rem; }
  .line-numbers .line-numbers-rows { border-color: #191919; }
  code { padding: 0; border: none; }
} // .editor