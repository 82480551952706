// Coopers Shop
.status { margin-top: rem-calc(45); color: #141414; }

.coopers-shop { margin-bottom: rem-calc(100); 
  @include breakpoint(large) { h3 { font-size: rem-calc(30); } }
}

.selecting-restricted {
  .general-selection { margin-bottom: 3rem; 
    li { margin-bottom: 2rem; }
  }
  .options-for-select { padding: 2rem; border-bottom: 1px #cfcfcf solid; }
}

.coopers-shop .card { margin-bottom: $global-padding * 3; max-width: rem-calc(656); 
  @include breakpoint(medium only) { margin-left: auto; margin-right: auto; }

  .content { margin-top: rem-calc(120); @include breakpoint(medium) { padding-left: $global-padding * 3; padding-right: $global-padding * 3; }  
  .lead { margin: 30px 0; line-height: 2; }
    form { color: #141414;
      fieldset { padding-bottom: 2rem; }
      legend { padding: 1.3rem 0; }
      textarea { min-height: 300px; }
      .cat-select { display: block; padding-bottom: 1rem; }
      .cat-prod { display: block; 
        @include breakpoint(large) { display: inline-block; width: 48%; }
        @include breakpoint(xlarge) { width: 32%; }
      }
      .prod-select { max-height: 220px; padding: rem-calc(10); border: 1px solid #cecece; overflow-y: scroll; 
        li { display: inline-flex; align-items: baseline; width: 100%; }
      }
      .check-block__confirm { display: inline-flex; align-items: baseline; margin-bottom: 40px; }
      .button.submit { display: block; margin: 0 auto; } 
    }
  } //.coopers-shop .card .content
  .stripes { position: absolute; width: 100%; height: 150px; margin-top: -40px; margin-left: -40px;
    &::before { top: 0; left: 0; width: 100%; height: 150px; transform: skewY(-12deg); z-index: 2; }
    &::before, &::after { content: ""; position: absolute; background: $coopers; transform-origin: 0; }
  } // .coopers-shop .card .stripes 

} // .coopers-shop .card

.help-information { padding-top: $global-padding * 3;
  div { padding-top: $global-padding; padding-bottom: $global-padding; }
  h3 { padding: 2rem 0; }
  p { max-width: 270px; line-height: 2; }
}


//error app__form
.app__form {
  .is-invalid-label { color: #141414; }
  .is-invalid-input:not(:focus) { border-color: #EBA20F; }
  .is-invalid-input { margin-bottom: 5px; }
    // placeholder color
    .is-invalid-input:not(:focus)::placeholder { color: #EBA20F; opacity: .7; }
    .is-invalid-input:not(:focus)::-webkit-input-placeholder { color: #EBA20F; opacity: .7; }
    .is-invalid-input:not(:focus)::-ms-input-placeholder { color: #EBA20F; opacity: .7; }
  .error-message { margin-bottom: 20px; padding: 13px; border: 1px solid #EBA20F; color: #737373;
    p:first-child { margin-bottom: 0; font-weight: bold; color: #EBA20F; }
  }
  .form-error.required-field { display: inline-block; margin-bottom: .3rem; color: #EBA20F; }
}