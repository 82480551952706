@keyframes coverFade {
  from { opacity: 0; transform: translateY(-5px); }
  to { opacity: 1; transform: translateY(0); }
}
@keyframes fadeFromTop {
  from { margin-top: -10px; opacity: 0; }
  to { margin-top: 0; opacity: 1; }
}
@keyframes fadeFromLeft {
  from { transform: translate3d(-10px,0,0); opacity: 0; }
  to { transform: translate3d(0,0,0); opacity: 1; }
}

@keyframes rotating {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}