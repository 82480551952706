/*
  --------------
  ELEMENTS e CLASSES
  --------------
*/

:root {
  --site-height: 100%;
}

/* ------ ELEMENTS ------- */
*, *::before, *::after { box-sizing: border-box; }
body { overflow-x: hidden; }

  // titles
  h1, h2, h3, h4, h5, h6 {
    &.feature { color: $coopers; }
    span { display: block; }
    small { font-size: .75em; color: inherit; }
  }
  h1 {
    &.large { font-size: rem-calc(36);
      @include breakpoint(medium) {
        font-size: rem-calc(60); 
      }
      @include breakpoint(large) {
        font-size: rem-calc(90); 
      }
    }
  } // h1
  h2, h3, h4, h5, h6 { color: #000; }
  h6 { font-weight: bold; text-transform: uppercase; color: $coopers;
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(30);
    }
  }

  // links
  a {
    &, .more { transition: all .3s $animation-easeoutquint; }
    .more { position: relative; display: inline-block; width: auto; font-size: rem-calc(12); font-weight: bold; letter-spacing: 1px; text-transform: uppercase; color: $coopers; }
    &.more:hover, &:hover .more { color: darken($coopers, 10%); }
  }

  // forms
  input:not([type]) { display: block; width: 100%; height: $input-line-height * 1em; box-sizing: border-box; margin-bottom: rem-calc(18); padding: $input-padding; border: $input-border; font-size: $input-font-size; color: $input-color; }
  input:not([type]), [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    &:focus { border-color: $black; border-style: solid; }
  }
  input:not([type]), [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] { padding: 18px 0; border-width: 0 0 1px;
    &:focus { border-width: 0 0 1px; }
  }
  [name='phone_number'] { display: none; }
  .is-invalid-input:not(:focus) { background: transparent; }
  select { font-size: 1em;
    &, &:focus, &:not([multiple]) { height: auto; padding: 10px 2em 10px 0; border-width: 0 0 1px; border-color: #979797; border-style: solid; border-radius: 0; background-color: #fff; }
  }
  textarea { padding: 18px;
    &, &:focus { border-width: 1px; border-radius: 0; }
  }
  label em { color: $coopers; }
  .form-error { margin-bottom: 2 * $global-padding; }


/* ------- CLASSES ------- */
.grid-container { padding-right: 1em; padding-left: 1em;
  @include breakpoint(medium) { 
    padding-right: rem-calc(30); padding-left: rem-calc(30);
  }
  &--medium {
    @include breakpoint(large) { 
      padding-right: rem-calc(90); padding-left: rem-calc(90);
    }
    @include breakpoint(xxlarge) { 
      padding-right: rem-calc(175); padding-left: rem-calc(175); 
    }
  }
  &--narrow { max-width: rem-calc(1000); }
}

  // buttons
  .button { box-shadow: 0 rgba(0,0,0,0); font-weight: bold; text-transform: uppercase; transition: all .3s $animation-easeoutquint;
    @include breakpoint(medium) { 
      padding-right: 3.66em; padding-left: 3.66em;
    }
    &.app-page { padding-right: 1em; padding-left: 1em; }

    &:hover { box-shadow: 0 9px 17px 0 rgba(0,0,0,0.08); transform: translate3d(0,-5%,0); }

    &.secondary { background-color: #fff; color: #31964B;
      &:hover { background-color: $white; }
    }
  } // .button

  .button-group {
    .button { margin-bottom: 0; }
  }

.card { position: relative; z-index: 1; padding: $card-padding;
  a {
    .cell { color: $body-font-color; }
  }
  &-whatsapp__number { font-size: 1.1em; }
} // .card

.desaturate { filter: grayscale(100%); }

.stripe--footer { position: relative; padding-bottom: 170px;
  @include breakpoint(medium) { 
    padding-bottom: 250px;
  }
  @include breakpoint(large) { 
    padding-bottom: 330px;
  }
  @include breakpoint(xxlarge) { 
    padding-bottom: 430px;
  }

  &::before { background: $coopers; transform: skewY(-12deg); }
  &::after { background: $white; transform: translateY(10px) skewY(-11.5deg); }
  &::before, &::after { content: ""; position: absolute; bottom: 0; left: 0; width: 100vw; height: 40px; transform-origin: 0; }
}

.separator {
  &::before { content: ""; display: block; width: 100%; height: 1px; margin: rem-calc(30) auto rem-calc(40); background: #dbdbdb;
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(60);
    }
  }
}

.switch { 
  .switch-active { left: -60px; color: #000; }
  .switch-inactive { left: 100px; color: #747474; }
  .switch-active, .switch-inactive { display: block; font-weight: 400; }
  input:checked ~ .switch-paddle::after { background: #b3b3b3; }
  input:checked + label > .switch-inactive { display: block; color: #000; }
  input:checked + label > .switch-active { color: #747474; }
  .switch-paddle { margin-left: rem-calc(60); margin-right: rem-calc(80); border: 1px solid #979797; }
} //.switch

  // menus
  [data-responsive-accordion-tabs].tabs { display: flex; margin-bottom: rem-calc(30); padding: rem-calc(30) 0; border-right: none; border-left: none;border-bottom: 1px solid #D8D8D8; list-style: none;
    @include breakpoint(large) { 
      margin-bottom: rem-calc(120);
    }
    &-title { margin: 0 5px; }
    &-content { border: none; background: transparent; }
    a { display: inline-block; min-width: 190px; max-width: 100%; padding: 10px 5px; border-radius: 50px; border: 1px solid transparent; font-weight: bold; font-size: rem-calc(12); text-transform: uppercase; text-align: center; color: rgba(20,20,20,.5); }
    a:hover, .is-active a { color: $black; }
    .is-active {
      a { border-color: rgba(20,20,20,.4); background: transparent; }
    }
  }

  .accordion {
    .grid-container > & { padding-top: 1rem; }
    &-item { margin-bottom: $global-padding; }
    &-title { font-weight: bold; font-size: rem-calc(12); text-transform: uppercase; text-align: center; color: rgba(20,20,20,.5);
      .accordion-item:first-child > &:first-child, :last-child:not(.is-active) > &, 
      & { border-radius: 50px; border: 1px solid $light-gray; }
      &:hover, .is-active & { color: $black; }
      .accordion-item.is-active:first-child > &:first-child,
      .is-active & { border-color: rgba(20,20,20,.4); background: transparent; }
    }
    &-content { border: none; }
  }

  .app-menu { padding-right: $global-padding;
    ul.menu { margin: rem-calc(60) auto rem-calc(20); max-width: 353px; 
      @include breakpoint(large) { margin: rem-calc(60) 0 rem-calc(20); }
    }
    li { line-height: 2; margin: .5px; padding: 20px; background-color: #cecece; font-size: rem-calc(20); color: #fff; 
      @include breakpoint(large only) { font-size: rem-calc(18); }
      &.active { background-color: $primary-color; }
      a { color: #fff; }
    }
  }

  // sections
  .section--intro { margin-top: rem-calc(30);
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(20);
    }
    @include breakpoint(large) { 
      margin-top: rem-calc(50);
    }

    img, h2 { margin-bottom: rem-calc(30);
      @include breakpoint(large) { 
        margin-bottom: 0;
      }
    }

    h2 {
      @include breakpoint(medium) { 
        font-size: rem-calc(38); 
      }
      @include breakpoint(large) { 
        margin-bottom: rem-calc(30);
      }
      @include breakpoint(xlarge) { 
        font-size: rem-calc(48); 
      }
    } // h2

    .cell {
      h2 {
        @include breakpoint(large) { 
          margin-bottom: 0;
        }
      }
    }

    &--about { margin-top: 1em; margin-bottom: 1em;
      @include breakpoint(medium) { 
        margin-top: rem-calc(60); margin-bottom: rem-calc(60);
      }
      @include breakpoint(large) { 
        margin-top: rem-calc(110); margin-bottom: rem-calc(110);
      }
    }

    section + &.separator {
      @include breakpoint(large) { margin-top: -1 * rem-calc(100); }
    }
  } // section--intro
  .section--blog, .section--post {
    @include breakpoint(large) {
      margin: rem-calc(110) 0;
    }
  }
  .section--post {
    h3 {
      font-weight: bold;
        width: 50%;
      @include breakpoint(large) { 
        width: 35%;
        font-size: rem-calc(36);
      }
      
      &.full-width { width: 100%; height: auto; margin: 0 0 1em; }
    }
    p, h3 {
      margin-bottom: rem-calc(25);
    }
    p.strong, h3 {
      margin-top: rem-calc(40);
    }
    p {
      &.strong {
        font-weight: bold;
        margin-bottom: 0;
      }    
    }
    p, li {
      color: $black;
    }
    figure {
      margin: rem-calc(50) 0 rem-calc(40);
    }
    img {
      width: 100%;
      margin-bottom: 0;
    }
    figcaption {
      font-size: rem-calc(12);
      margin: rem-calc(10) 0;
    }
  } //section--post
  // content
  .brand {
    h2 { display: inline-block; margin-bottom: 0; }
    use, mask { transition: all .3s $animation-easeoutquint; }
  }
  
  p.lead {
    &.small, &.medium { margin: 0 auto $global-padding;
      &:last-of-type { margin-bottom: rem-calc(30);
        @include breakpoint(medium) { 
          margin-bottom: rem-calc(60);
        }
      }
    }
    &.small { max-width: 495px; }
    &.medium { max-width: 865px; }
  }

  .cover-photo { position: relative; width: 100%; height: 300px; margin-bottom: rem-calc(30); object-fit: cover; z-index: 1;
    @include breakpoint(large) { 
      height: 400px; margin-bottom: rem-calc(110);
    }
  }

  .full-width { width: 100%; height: 300px;
    @include breakpoint(large) { 
      height: 730px;
    }

    &, img { object-fit: cover; }

    img { width: 100%; height: 100%; }

    &--stripe { position: relative; height: 300px; overflow: hidden;
      @include breakpoint(large) { 
        height: 830px;
      }

      &::before { bottom: 0; width: 100%; height: 100%; background: $white; transform: skewY(-12deg) translateY(100%); }
      &::after { top: 72%; right: 0; width: 40%; height: 20%; background: $coopers; transform: skewY(-12deg);
        @include breakpoint(medium) { 
          top: 50%; height: 30%;
        }
        @include breakpoint(large) { 
          top: 75%; height: 15%;
        }
        @include breakpoint(xlarge) { 
          top: 70%;
        }
        @include breakpoint(xxlarge) { 
          top: 65%;
        }
      }
      &::before, &::after { content: ""; position: absolute; transform-origin: 0; }

      img { width: 100%; height: 100%; object-fit: cover;
        @include breakpoint(medium) { 
          object-position: center 80%;
        }
      }
    }
  } // .full-width

  .team {
    .grid-x { margin-bottom: rem-calc(40);
      @include breakpoint(medium) { 
        margin-bottom: rem-calc(60);
      }
      @include breakpoint(large) { 
        margin-bottom: rem-calc(110);
      }

      &:last-child {
        @include breakpoint(small only) { margin-bottom: 0; }
      }
    }
    
    .cell {
      &:first-child { margin-bottom: rem-calc(20);
        @include breakpoint(medium) { 
          margin-bottom: 0;
        }
      }
    }

    h2 {
      @include breakpoint(medium) { 
        font-size: rem-calc(36);
      }
      @include breakpoint(large) { 
        font-size: rem-calc(48);
      }
    }

    p:last-of-type {
      @include breakpoint(medium) { 
        margin-bottom: rem-calc(40);
      }
    }
    a:last-child { font-weight: bold; font-size: rem-calc(12); }
    ul {
      li { margin-bottom: rem-calc(10); }
    }
    svg { vertical-align: middle; }
  } // .team

  p.role { font-size: rem-calc(12); font-weight: bold; text-transform: uppercase; color: $coopers;
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(40);
    }
  }

  .works { 
    @include breakpoint(medium) { 
      padding-top: rem-calc(40);
    }

    &.stripe--footer {
      @include breakpoint(large) { 
        padding-bottom: 200px;
      }
      @include breakpoint(xlarge) { 
        padding-bottom: 300px;
      }
    }

    .section--intro {
      @include breakpoint(medium) { 
        margin-bottom: rem-calc(60);
      }
      @include breakpoint(large) { 
        margin-bottom: rem-calc(100);
      }

      p.lead { margin-bottom: rem-calc(30); }
    }

    svg, img { height: 50px; }
    img { width: 50px; object-fit: contain; }
  } // .works

  .privacy-policy,
  .terms-of-use,
  .cookie-policy {
    @include breakpoint(medium) {
      padding-top: rem-calc(40);
    }

    &.stripe--footer {
      @include breakpoint(large) {
        padding-bottom: 200px;
      }  
      @include breakpoint(xlarge) {
        padding-bottom: 300px;
      }
    }

    h2 {
      margin-top: rem-calc(40);
      margin-bottom: rem-calc(20);
    }

    ul {
      margin-bottom: rem-calc(30);
      list-style-position: inside;
    }
  }

  ul.dashes {
    li::before { content: "- "; }
  }

  .whatsapp-float {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: transparent;
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    img {
      width: 4rem;
      height: 4rem;
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
    }

    &:hover {
      transform: translateY(-2px);
      img {
        filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.2));
      }
    }

    @media print {
      display: none;
    }
  } // .whatsapp-float