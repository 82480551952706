.footer { position: relative; padding-bottom: rem-calc(30);
  @include breakpoint(large) { 
    padding-bottom: rem-calc(50);
  }
  .cell { margin-bottom: rem-calc(20);
    @include breakpoint(medium) { 
      margin-bottom: 0;
    }
  }
  .brand {
    h2 { margin-bottom: rem-calc(30);
      @include breakpoint(medium) { 
        margin-bottom: rem-calc(50);
      }
      @include breakpoint(large) { 
        margin-bottom: rem-calc(100);
      }
    }
  }
  h3 { margin-bottom: rem-calc(20); font-weight: bold; font-size: rem-calc(14);
    @include breakpoint(large) { 
      margin-bottom: rem-calc(40);
    }
  }
  a { color: #141414;
    &:hover { color: $coopers; }
  }
  ul.no-bullet {
    li { margin-bottom: 10px; }
    a { padding: 10px 0; }
  }
  .social { margin-bottom: rem-calc(20);
    li:first-child a { padding-left: 0; }
    svg { width: 23px; height: 18px;
      path { fill: currentColor; }
    }
  }

  .copyright { color: #141414;
    @include breakpoint(medium only) { margin-top: rem-calc(20); }
    address { margin-top: $global-padding; font-style: normal;
      @include breakpoint(xlarge) { 
        display: inline-block; margin-top: 0; padding-left: 10px;
      }
      span { display: block; 
        @include breakpoint(large) { 
          display: inline;
          
          &::before { content: " | "; }
        }
      }
    }
    &__link { 
      &.button.hollow { margin-bottom: 0; padding: rem-calc(13 60); border-radius: 30px; border-color: black; color: black;
        &:hover { border-color: $primary-color; background-color: $primary-color; box-shadow: none; color: $white; transform: none; }
      }
    }
    .whatsapp-link { margin-left: 7px; vertical-align: sub;
      svg { width: 23px; height: 18px;
        path { fill: currentColor; }
      }
    }
  }
} // .footer