.oval { position: absolute; width: 100%; height: 780px; background: #141414 url(../img/clients-testimonials-circle.svg) center bottom no-repeat; background-size: 1600px auto; transform: translate3d(0,-50px,0); }

.clients-testimonials { position: relative; padding-bottom: 90px; color: #fff;
  @include breakpoint(large) { 
    padding-bottom: 220px; 
  }
  .stripes { left: 0; width: 100%; height: 820px; background: #fff; overflow: hidden; transform: skewY(-12deg) translate3d(0,182px,0); transform-origin: 0;
    @include breakpoint(medium) { 
      transform: skewY(-12deg) translate3d(0,202px,0); 
    }
    @include breakpoint(large) { 
      transform: skewY(-12deg) translate3d(0,280px,0); 
    }
    @include breakpoint(xlarge) { 
      transform: skewY(-12deg) translate3d(0,300px,0); 
    }
    @include breakpoint(xxxlarge) { 
      transform: skewY(-12deg) translate3d(0,340px,0); 
    }
    &, &::before, &::after { position: absolute; }
    &::before, &::after { content: ""; position: absolute; top: 0; right: 0; }
    &::before { right: 275px; width: 450px; height: 260px; background: #28D061; }
    &::after { width: 1400px; height: 180px; background: #23C45A;
      @include breakpoint(large) { 
        width: 80%; 
      }
    }
  }
  h2, p.lead, .grid-x, .cell { position: relative; }
  h2 { color: inherit; }
  p.lead { color: $coopers;
    @include breakpoint(medium) { 
      margin-bottom: rem-calc(50); 
    }
    @include breakpoint(large) { 
      margin-bottom: rem-calc(100); 
    }
  }

  &__left, &__right { padding: rem-calc(20) 0;
    @include breakpoint(large) { 
      padding: rem-calc(50) 0;
    }
  }

  &__container {
    &.fluid { max-width: 1750px; }
    &.loading { position: relative; 
      &::before, .loader { opacity: 1; }
      > .grid-x { opacity: 0; }
    }

    &::before, .loader, > .grid-x { transition: all .4s ease-in-out; }
    &::before, .loader { @include absolute-center; opacity: 0; pointer-events: none; }
    &::before { content: ""; width: 40px; height: 40px; border-radius: 100%; background: rgba(0,0,0,.8); z-index: 2; }
    .loader { width: 20px; height: 20px; margin: -10px 0 0 -10px; z-index: 3; animation: rotating 1.5s linear infinite; }
  }
} // .clients-testimonials

.clients-testimonials__brand { position: absolute; display: inline-block; width: 43%; height: 90px; border-radius: $global-radius; background: #FFF; box-shadow: 0 9px 17px 0 rgba(0,0,0,0.08); text-align: center;
  &:first-child, &:nth-child(2) { margin-top: -1 * rem-calc(20); 
    @include breakpoint(large) { 
      margin-top: -1 * rem-calc(50);
    }
    
    .clients-testimonials__right & { right: $global-padding; }
  }
  &:first-child { top: 50%; transform: translate3d(0, -75%,0);
    &:not(span):hover, &.active { transform: translate3d(0, -73%,0); }
  }
  &:nth-child(2) { top: 50%; transform: translate3d(0, 75%,0);
    &:not(span):hover, &.active { transform: translate3d(0, 77%,0); }
  }
  &:nth-child(3), &:nth-child(4), &:nth-child(5) {
    .clients-testimonials__left & { right: $global-padding; }
  }
  &:nth-child(3), &:nth-child(5) {
    &:not(span):hover, &.active { transform: translate3d(0,2%,0); }
  }
  &:nth-child(3) { margin-top: 30px; }
  &:nth-child(4) { @include vertical-center;
    &:not(span):hover, &.active { transform: translate3d(0,-48%,0); }
  }
  &:nth-child(5) { margin-bottom: 30px; bottom: rem-calc(20);
    @include breakpoint(large) { 
      bottom: rem-calc(50);
    }
  }

  svg, img { @include absolute-center; max-width: 70%; max-height: 70%; transition: all .3s $animation-easeoutquint; backface-visibility: hidden; }
  svg {
    g, path, use { fill: #000; }
    #Home-Expandida polyline { fill: #fff; }
    path[fill="#8A8A8A"] { fill: #6d6d6d; }
  }
  &:not([href]), &.clients-testimonials__brand--no-testimonial {
    svg, img { opacity: .3; }
  }

  &:not(span):hover, &.active { box-shadow: 0 9px 17px 0 rgba(0,0,0,0.2); 
    img { opacity: 1; }
    svg { opacity: 0;
      &.injected-svg { opacity: 1; }
    }
  }
  &.active { background-color: #222;
    svg {
      g, path, use { fill: #fff; }
      #Home-Expandida polyline { fill: #000; }
      path[fill="#8A8A8A"] { fill: #6d6d6d; }
      &[data-src*="boticario.svg"] g, &[data-src*="boticario.svg"] rect { fill: transparent; }
    }
    img { filter: grayscale(1) invert(1); }
  }
} // .clients-testimonials__brand

.clients-testimonials__testimonial__container {
  @include breakpoint(large) { 
    height: 530px; 
  }
}

.clients-testimonials__testimonial { position: relative; height: 0; box-shadow: 0 9px 17px 0 rgba(0,0,0,0.08); border-radius: $global-radius; background: #fff; overflow: hidden; visibility: hidden;
  .stripe { position: absolute; top: 0; left: 50%; width: 100vw; height: 250px; margin-left: -50vw; background: #28D061; transform: skewY(-12deg) translate3d(0,-68px,0); transform-origin: 0;
    @include breakpoint(medium) { 
      transform: skewY(-12deg) translate3d(0,-47px,0);
    }
    @include breakpoint(large) { 
      transform: skewY(-12deg) translate3d(0,30px,0);
    }
    @include breakpoint(xlarge) { 
      transform: skewY(-12deg) translate3d(0,50px,0);
    }
    @include breakpoint(xxxlarge) { 
      transform: skewY(-12deg) translate3d(0,90px,0);
    }
  }

  &__profile, blockquote, &__brand { opacity: 0; }
  &__profile { margin-bottom: 80px; transform: translate3d(-10px,0,0); transition: all .4s .6s $animation-easeoutquint; 
    @include breakpoint(large) { 
      margin-bottom: 120px;
    }
    .shrink { padding-right: $global-padding; }
    img { width: 84px; height: 84px; border-radius: 100px; }
    h6, p { color: #fff; }
    h6 { margin-bottom: 7px; font-size: rem-calc(18); font-weight: normal; text-transform: none; color: #000; }
    p { max-width: 250px; margin-bottom: 0;
      @include breakpoint(medium) { 
        max-width: 220px;
      }
      @include breakpoint(large) { 
        max-width: 330px;
      }
      @include breakpoint(xxlarge) { 
        max-width: 200px;
      }
    }
  }

  blockquote { position: relative; display: block; max-width: 540px; min-height: 90px; margin-right: auto; margin-left: auto; padding: 0; border: 0; transform: translate3d(0,10px,0); transition: all .5s .25s $animation-easeoutquint; 
    &::before, &::after { content: ""; position: absolute; width: 28px; height: 22px; background: url(../img/icons/quote.svg) no-repeat; }
    &::after { right: 0; transform: translate3d(0,-180%,0) rotate(180deg); }
    p { padding: 0 35px; font: italic rem-calc(20) "Times New Roman", serif; text-align: center; color: #323232;
      @include breakpoint(medium) { font-size: rem-calc(24); }
    }
  }

  &__brand { position: absolute; right: $global-padding; bottom: $global-padding; max-width: 115px; max-height: 60px; transform: translate3d(10px,0,0); transition: all .4s .9s $animation-easeoutquint; 
    @include breakpoint(xlarge) { 
      right: rem-calc(40); bottom: rem-calc(40);
    }
  }

  .no-js &:first-child, &.active { height: auto; padding: rem-calc(40) $global-padding rem-calc(80); visibility: visible;
    @include breakpoint(medium) { 
      height: 100%; padding-right: rem-calc(40); padding-left: rem-calc(40);
    }
    .clients-testimonials__testimonial__profile, blockquote, .clients-testimonials__testimonial__brand { opacity: 1; transform: translate3d(0,0,0); }
  }

  .clients-testimonials__testimonial__container &__pagination { position: absolute; bottom: 0; width: calc(100% - 30px); padding: 0 rem-calc(20); transform: translate3d(0,40px,0);
    @include breakpoint(large) { 
      padding: 0 rem-calc(50);
    }
    &__step { position: relative; max-width: 70px; height: 15px; margin: 0 10px; cursor: pointer;
      &::after { content: ""; position: absolute; bottom: 0; width: 100%; height: 1px; background: #BABABA; transition: all .2s $animation-easeoutquint; }
      .no-js &:first-child, &:hover, &.active {
        &::after { height: 3px; background: $coopers; }
      }
    }
  }
} // .clients-testimonials__testimonial