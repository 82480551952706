.header { padding-top: $global-padding; padding-bottom: $global-padding; background: #141414; color: #fff;
  @include breakpoint(large) { 
    padding-top: rem-calc(40);
  }
  
  &, .grid-container { position: relative; }

  & > .grid-container > .grid-x { min-height: 54px; margin-bottom: 1em;
    @include breakpoint(large) { margin-bottom: 0; }
  }

  .brand { padding: rem-calc(15) 0;
    &.white {
      &:hover {
        #brand-white-arrow {
          use { fill: $coopers; }
        }
      }
    }
  }

  .off-canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: $global-radius;
    padding: $global-padding;
    opacity: 0;
    transition: opacity 0.3s ease;

    @include breakpoint(large) {
      flex-direction: row;
      width: 100%;
      height: auto;
      padding-right: 0;
      opacity: 1;
    }

    &.is-open {
      opacity: 1;
      z-index: 14; // Ensure it's below the hamburger button
    }

    nav { max-width: 500px;
      margin-bottom: 2em;
      font-size: 1.5rem;

      @include breakpoint(large) {
        max-width: 100%;
        margin-bottom: 0;
        font-size: 1rem;
      }
    }

    aside {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: auto;
      font-size: 1.2rem;

      @include breakpoint(large) {
        font-size: 1rem;
      }

      .social {
        a { padding: 0 5px;
          @include breakpoint(medium) { 
            padding: .7rem 1rem;
          }
        }
      }
      .language {
        border-left: 1px solid $black;
        margin-left: 10px;
        padding-left: 15px;

        @include breakpoint(large) {
          border-left-color: $white;
        }

        &.menu {
          display: flex;
          li {
            margin: 0;
            padding: 0;
            a {
              padding: 0 5px;
            }
          }
        }

        img {
          height: 20px;
          width: 20px;
          transform: translateY(-1px);
          vertical-align: middle;
          object-fit: contain;
        }
      }
    }
  }

  .cell {
    @include breakpoint(large) { 
      position: relative; z-index: 2; 
    }
  }

  nav { margin-bottom: 0;
    @include breakpoint(large) { 
      display: inline-block;
    }

    ul {
      @include breakpoint(large) { 
        padding-top: 2px; justify-content: flex-end;
      }
    }
  }

  a { position: relative;
    @include breakpoint(large) { 
      color: #fff;
    }

    &:not(.button):hover {
      @include breakpoint(large) { 
        color: $coopers;
      }
    }
  }

  .is-active a, .is-active a:hover { color: #fff; }

  .hamburger { position: relative; z-index: 15;
    &.is-active { position: fixed; top: 1em; right: 1em;
      @include breakpoint(medium) { right: 2em; }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after { background-color: $black; }
    }
  }

  .menu {
    .is-active a { position: relative; color: $coopers; }
  }

  aside { padding-top: $global-padding; border-top: 1px solid $light-gray;
    @include breakpoint(large) { 
      padding-top: 0; border-top: 0;
    }
  }

  .social { 
    svg { width: 20px; height: 13px;
      path { fill: currentColor; }
    }
  }

  .stripes { position: absolute; top: 0; width: 100%; height: 100%; overflow: hidden;
    &::before { transform: skewY(-24deg); animation: fadeFromTop 1s $animation-easeoutquint 1s 1 forwards; }
    &::after { transform: skewY(-24deg); animation: fadeFromTop 1s $animation-easeoutquint 1.2s 1 forwards; }
    span { transform: skewY(-24deg); animation: fadeFromTop 1s $animation-easeoutquint 1.4s 1 forwards; }
    &::before, span, &::after { content: ""; position: absolute; width: 840px; height: 330.49px; box-shadow: 1px 1px 1px rgba(0,0,0,0); background: linear-gradient(90deg, #141414 0%, #1e1e1e 100%); opacity: 0; transform-origin: 0; backface-visibility: hidden; }

    &::before { right: -10%; }
    &::after { top: 110%; left: 10%;
      @include breakpoint(xlarge) { 
        top: 100%;
      }
    }
    span { top: 88%; right: 0;
      @include breakpoint(medium) { 
        top: auto; right: 44%; bottom: -85%;
      }
      @include breakpoint(large) { 
        right: 34%;
      }
      @include breakpoint(xlarge) { 
        right: 14%;
      }
    }
  } // .stripes

  .cover { position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; transform: translateY(-10px); object-fit: cover; animation: coverFade 1.5s $animation-easeoutquint 1s 1 forwards; }

  .content { position: relative; z-index: 2;
    @include breakpoint(large) { 
      padding-top: rem-calc(100);
    }

    > .button-group { padding-top: rem-calc(30);
      @include breakpoint(small only) { 
        flex-wrap: wrap;
      }
      @include breakpoint(medium) { 
        padding-top: rem-calc(60);
      }

      .button { 
        @include breakpoint(small only) { 
          flex: 0 0 100%; margin-bottom: rem-calc(10);
        }
      }
    }

    > .grid-x {
      > .large-8 {
        @include breakpoint(medium) { 
          padding-left: 80px;
        }
        @include breakpoint(large) { 
          padding-right: 30px;
        }
        @include breakpoint(xlarge) { 
          width: 60%;
        }

        p { max-width: 580px; color: rgba(255,255,255,.5);
          @include breakpoint(large) { 
            font-size: rem-calc(16);
          }
        }
      }
      > .large-4 {
        @include breakpoint(xlarge) { 
          width: 40%;
        }

        .button-group {
          @include breakpoint(medium) { 
            padding-left: 80px;
          }
          @include breakpoint(large) { 
            position: absolute; bottom: 0; width: 100%; padding-left: 0; justify-content: flex-end;
          }

          .button {
            @include breakpoint(large) { 
              max-width: 170px; padding-right: 0; padding-left: 0; flex: 1 1 0px;
            }
          }
        }
      }
    }
  }

  h1 {
    strong { display: block; margin-bottom: 10px; font-size: rem-calc(12); font-weight: normal; text-transform: uppercase; color: rgba(255,255,255,.5); }
  }

  &__icon, &__pagination {
    path:not(.icon-stripe) { fill: #fff; transition: all .3s $animation-easeoutquint; }
  }

  &__icon {
    @include breakpoint(medium) { 
      position: absolute; top: 10px; left: 0;
    }
  }

  &__pagination { margin-bottom: $global-padding;
    @include breakpoint(large) { 
      text-align: right;
    }

    a:hover {
      path { fill: $coopers; }
    }
    svg { width: 20px; height: 16px; vertical-align: middle; }
  }

  &__subnav { margin-left: 0; border-width: 0 0 1px; border-style: solid; border-color: #2b2b2b; list-style: none;
    @include breakpoint(medium) { 
      margin-top: $global-padding; padding-top: $global-padding; border-width: 1px 0 0;
    }
    @include breakpoint(large) { 
      margin-top: rem-calc(110); 
    }

    .cell { text-align: center;
      &:first-child {
        @include breakpoint(medium) { 
          text-align: left; 
        }
      }
      &:nth-child(2) {
        @include breakpoint(medium) { 
          text-align: center; 
        }
      }
      &:nth-child(3) {
        @include breakpoint(medium) { 
          text-align: right; 
        }
      }
    }
    li { margin: 5px 0; }
    a { display: inline-block; min-width: 220px; max-width: 100%; padding: 10px 5px; font-weight: bold; font-size: rem-calc(12); text-transform: uppercase; text-align: center; color: rgba(255,255,255,.5); }
    .is-active {
      a { border: 1px solid #fff; border-radius: 50px; }
    }
  } // &__subnav

  &--home { padding-bottom: rem-calc(50); background: $coopers;
    @include breakpoint(medium) { 
      padding-bottom: rem-calc(100);
    }

    .stripes {
      &::before, span, &::after { height: 230px; background-image: linear-gradient(90deg, $coopers 0%, #42D76B 100%); }
      &::before { top: 20%; right: auto; left: 7%; }
      &::after {
        @include breakpoint(xlarge) { 
          top: 80%;
        }
      }
      span { top: auto; bottom: -20%; }
    }
    
    .content {
      @include breakpoint(large) { 
        padding-top: 30px;
      }

      > .button-group { padding-top: 0; }
    }
    .brand.white {
      &:hover {
        #brand-white-arrow, #brand-white-text {
          use { fill: #141414; }
        }
      }
    }
    h1, p.lead { opacity: 0; }
    h1 { margin-bottom: rem-calc(15); animation: fadeFromLeft 1s $animation-easeoutquint 1s 1 forwards;
      @include breakpoint(medium) { 
        margin-bottom: rem-calc(30);
      }
    }
    p.lead { margin-bottom: rem-calc(30); animation: fadeFromLeft 1s $animation-easeoutquint 1.5s 1 forwards;
      @include breakpoint(large) { 
        margin-bottom: rem-calc(50);
      }
    }
    a:not(.button):hover {
      @include breakpoint(large) { 
        color: #141414;
      }
    }
  } // &--home

  &--contact, &--about {
    .stripes { display: none; }
  }

  &--about {
    @include breakpoint(large) { 
      padding-bottom: 160px;
    }

    &::before { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.5); z-index: 1; }
    .grid-container { z-index: 2; }
    h1 {
      span { display: inline;
        @include breakpoint(xlarge) { 
          display: block;
        }
      }
    }
  }

  &--works { padding-bottom: rem-calc(30);
    @include breakpoint(medium) { 
      padding-bottom: rem-calc(60);
    }
    @include breakpoint(large) { 
      padding-bottom: rem-calc(190);
    }
  }

} // .header