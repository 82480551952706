.contact { position: relative; 
  @include breakpoint(medium) { 
    min-height: 1100px; transform: translateY(-100px);
  }
  @include breakpoint(large) { 
    margin-top: 120px;
  }

  &::before { top: 0; height: 80px; transform: skewY(-12deg);
    @include breakpoint(medium) { 
      height: 250px; transform: translateY(50px) skewY(-12deg);
    }
    @include breakpoint(xlarge) { 
      height: 270px;
    }
    @include breakpoint(xxlarge) { 
      height: 300px;
    }
    @include breakpoint(xxxlarge) { 
      height: 350px;
    }
  }
  &::after { bottom: 0; height: 350px; transform: skewY(-12deg) translateY(20%);
    @include breakpoint(medium) { 
      transform: skewY(-12deg) translateY(100%); 
    }
  }
  &::before, &::after { content: ""; position: absolute; width: 100%; background: $white; transform-origin: 0; z-index: 1; }

  > .stripes { position: absolute; width: 100%; height: 100%;
    &::before {
      @include breakpoint(medium) { 
         top: -30px; left: 0; width: 30%; height: 150px; transform: translateY(150%) skewY(-12deg); z-index: 2;
      }
      @include breakpoint(xlarge) { 
        top: 60px;
      }
      @include breakpoint(xxlarge) { 
        top: 90px;
      }
      @include breakpoint(xxxlarge) { 
        top: 140px;
      }
    }
    &::after { top: 100px; right: 0; width: 50%; height: 100%; transform: skewY(-12deg);
      @include breakpoint(medium) { 
        top: 0; 
      }
    }
    &::before, &::after { content: ""; position: absolute; background: $coopers; transform-origin: 0; }
  }

  > img { position: absolute; width: 100%; height: 200px; object-fit: cover; z-index: 1;
    @include breakpoint(medium) { 
      top: 50px; width: 50%; height: 100%; object-position: 55% 0; z-index: auto;
    }
    @include breakpoint(large) { 
      object-position: 47% -50px;
    }
  }

  &.right {
    > img { object-position: 85% 50%;
      @include breakpoint(medium) { 
        object-position: 65% 50%;
      }
      @include breakpoint(large) { 
        object-position: center;
      }
    }
  }
  
  // &.left {
  //   > img { object-position: 40% center; }
  // }

  .header + & { margin-top: 0;
    &::before { background: #141414; }

    > .stripes {
      &::before {
        @include breakpoint(medium) { 
          width: 188px;
        }
      }
    }
  }
} // .contact

.contact__form { position: relative; top: 180px; max-width: calc(100% - 2rem); margin: 0 auto 230px; padding: rem-calc(30 30 120); z-index: 3;
  @include breakpoint(medium) {
    top: 90px; left: 40%; width: 665px; max-width: 55%; margin: 0; padding: rem-calc(30 30 150);
  }
  @include breakpoint(large) {
    top: 0; left: 50%; max-width: 45%; padding: rem-calc(70 70 120); 
  }

  .stripes { position: absolute; left: 0; width: 100%; height: 100%;
    &::before { top: 0; left: 0; width: 100vw; height: 230px; transform: translateY(-130px) skewY(-12deg);
      @include breakpoint(medium) { 
        margin-left: -40vw; transform: translateY(-50px) skewY(-12deg);
      }
      @include breakpoint(large) { 
        margin-left: -50vw; transform: skewY(-12deg);
      }
      @include breakpoint(xlarge) { 
        height: 250px;
      }
      @include breakpoint(xxlarge) { 
        height: 280px;
      }
      @include breakpoint(xxxlarge) { 
        height: 330px;
      }
    }
    &::after { right: 0; bottom: 0; width: 100%; height: 280px; transform: translateY(80%) skewY(-12deg);
      @include breakpoint(medium) { 
        width: 100%; transform: translateY(75%) skewY(-12deg); 
      }
      @include breakpoint(large) { 
        width: 70%;
      }
    }
    &::before, &::after { content: ""; position: absolute; background: $coopers; transform-origin: 0; }
  } // .stripes

  header, ol { position: relative; }
  header { 
    svg { margin: 10px 0 80px;
      @include breakpoint(medium) { 
        margin: 10px 0 90px;
      }
      @include breakpoint(large) { 
        margin: -2% 0 100px; 
      }
    }
    p { line-height: 2; }
  } // header
  
  ol { color: $black; }
  p[id], ol > li:last-child { margin-top: 50px; }
  p[id] {
    em { color: $coopers; }
  }
  // select { margin-left: 2em; }
  button { position: absolute; right: 0; bottom: 0; width: 75%; height: 100px; color: #fff; cursor: pointer;
    svg { position: absolute; right: 45px; bottom: 35px; width: 20px; height: 16px; transition: all .3s $animation-easeoutquint;
      &.loading { right: 43px; bottom: 30px; width: 25px; height: 25px; opacity: 0; animation: rotating 1.5s linear infinite; }
    }
    g, path { fill: currentColor; }

    &:hover {
      svg { transform: translateX(5px); }
    }

    &.loading {
      .arrow { opacity: 0; }
      .loading { opacity: 1; }
    }
  } // button

  &--client {
    header { margin-bottom: rem-calc(15);
      @include breakpoint(medium) { margin-bottom: rem-calc(30); }
    }
    ol > li:last-child { margin-top: 0; }
  }
  
  // New autocomplete styles
  .autocomplete-wrapper {
    position: relative;
  }

  .autocomplete-list {
    position: absolute;
    z-index: 10;
    list-style: none;
    padding: 0;
    margin: 0;
    background: $white;
    border: 1px solid $medium-gray;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none; // Hide by default

    &:empty {
      display: none !important; // Ensure it's hidden when empty
      border: none;
      box-shadow: none;
    }

    li {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover, &.selected {
        background-color: $light-gray;
      }
    }
  }

  // Style the select element to match other inputs
  select#country {
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    padding-right: 30px;
  }

} // .contact__form

.contact-chat { position: relative;
  p.lead { margin-bottom: 50px;
    @include breakpoint(medium) { 
      margin-bottom: 100px; 
    }
  }

  .card { max-width: 500px; }

  svg { height: 100px; }
} // .contact-chat