// margin-top
@for $i from 0 through 4 {
  .mt#{$i} { margin-top: #{$i}em !important; }
}

// margin-bottom
@for $i from 0 through 4 {
  .mb#{$i} { margin-bottom: #{$i}em !important; }
}

// padding-top
@for $i from 0 through 4 {
  .pt#{$i} { padding-top: #{$i}em !important; }
}

// padding-bottom
@for $i from 0 through 4 {
  .pb#{$i} { padding-bottom: #{$i}em !important; }
}